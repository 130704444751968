import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Page from '../layout/Page';
import PageContentWrapper from '../layout/PageContentWrapper';
import styled from 'styled-components';
import { PageContentRow } from '../layout/PageContentRow';
import Breadcrumbs from '../Breadcrumbs';
import H2 from '../typography/H2';
import { useTranslation } from '../../../hooks/useTranslation';
import { useFetchNode } from '../../../hooks/useFetchData';
import { useNodes } from '../../../hooks/useNodes';
import BrowseContent from '../BrowseContent';
import Divider from '../layout/Divider';
import { useManuals } from '../../../hooks/useSearch';
import PDFList from '../PDFList';

const StyledH2 = styled(H2)`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.m};
`;

const SideBar = styled.div`
  flex: 1;
  max-width: 410px;
  padding-left: 10px;
  margin-top: 59px;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    max-width: 100%;
    margin-top: 0;
    padding-left: 0;
  }
`;

const BrowseSecondary = () => {
  const { t } = useTranslation();
  const { childId } = useParams<{ childId: string; manifestId: string }>();
  const { pathname } = useLocation();
  const path = pathname.split('/')[3];
  const { isLoading, error, data } = useFetchNode(`Parts.json`);
  const { breadcrumbs } = useNodes(data, childId, path);
  const { instructionManuals, assemblyManuals } = useManuals();

  return (
    <Page>
      <PageContentWrapper>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <PageContentRow>
          {!error ? (
            <>
              <BrowseContent
                parentNode={data}
                path={path}
                childId={childId}
                loading={isLoading}
              />
              <SideBar>
                <StyledH2>{t('partsandequipment.instructions')}</StyledH2>
                <Divider />
                <PDFList items={instructionManuals} manualType={false} />
                <StyledH2>{t('partsandequipment.accessories')}</StyledH2>
                <Divider />
                <PDFList items={assemblyManuals} manualType={false} />
              </SideBar>
            </>
          ) : (
            <div>Error message</div>
          )}
        </PageContentRow>
      </PageContentWrapper>
    </Page>
  );
};

export default BrowseSecondary;
