import { useState, useEffect } from 'react';
import { useStores } from '../context/StoreContext';

export default function useCheckAccess() {
  const { authStore } = useStores();
  const [hasAccess, setHasAccess] = useState(authStore.isTokenValid);

  useEffect(() => {
    const handleAuthChange = () => {
      setHasAccess(authStore.isTokenValid);
    };

    window.addEventListener('authChange', handleAuthChange);
    return () => window.removeEventListener('authChange', handleAuthChange);
  }, [authStore]);

  return hasAccess;
}
