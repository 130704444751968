import * as React from 'react';
import styled from 'styled-components';
import { useInternalLink } from '../../hooks/useInternalLink';

const Wrapper = styled.div`
  max-width: 640px;
  margin-bottom: 40px;
`;

const Link = styled.a`
  display: block;
  border-top: 1px solid #e8e8e8;
  &:last-child {
    border-bottom: 1px solid #e8e8e8;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.esabHoverLinks};
  }
`;

const Item = styled.div`
  font-size: 24px;
  line-height: 27.6px;
  font-weight: 400;
  padding: 20px 0;

  &:first-letter {
    text-transform: capitalize;
  }
`;

type LinkListProps = {
  items: Esab.Node[];
  parent?: string;
};

const LinkList: React.FC<LinkListProps> = ({ items, parent }) => {
  const { internalLink } = useInternalLink();

  if (items.length < 1) {
    return null;
  }

  const getHref = (
    hasChildren: boolean | undefined,
    topicId: string
  ): string => {
    if (hasChildren) {
      return `/${parent}/${topicId}`;
    }

    return `/${parent}/topics/${topicId}`;
  };

  return (
    <Wrapper>
      {items.map((item, index) => (
        <Link
          href={internalLink(
            getHref(
              item.children && item.children.length > 0,
              item.topicId ?? ''
            )
          )}
          key={index}
        >
          <Item>{item.title?.toLowerCase()}</Item>
        </Link>
      ))}
    </Wrapper>
  );
};
export default LinkList;
