export type InputObject = {
  key: string;
  value: string;
};

export const regions = [
  {
    key: 'AS',
    value: 'Asia Pacific',
    languages: ['en-GB,en-US', 'zh-CN', 'ko-KR'],
    countries: [
      'Australia',
      'Armenia',
      'Azerbaijan',
      'Bangladesh',
      'Bhutan',
      'Brunei',
      'Myanmar',
      'Cambodia',
      'China',
      'Fiji',
      'Georgia',
      'India',
      'Indonesia',
      'Japan',
      'Kazakhstan',
      'Kyrgyzstan',
      'South Korea',
      'Malaysia',
      'Maldives',
      'Marshall Islands',
      'Micronesia',
      'Mongolia',
      'Nepal',
      'New Zealand',
      'Niue',
      'Pakistan',
      'Palau',
      'Papua New Guinea',
      'Philippines',
      'Samoa',
      'Singapore',
      'Solomon Islands',
      'Sri Lanka',
      'Taiwan, China',
      'Tajikistan',
      'Turkmenistan',
      'Thailand',
      'Uzbekistan',
      'Vanuatu',
      'Viet Nam',
    ],
  },
  {
    key: 'ME',
    value: 'Middle East & Africa',
    languages: ['en-GB,en-US', 'tr-TR', 'ar-EG'],
    countries: [
      'Afghanistan',
      'Bahrain',
      'Iran',
      'Iraq',
      'Israel',
      'Jordan',
      'Kuwait',
      'Lebanon',
      'Oman',
      'Qatar',
      'Saudi Arabia',
      'Syria',
      'United Arab Emirates',
      'Yemen',
    ],
  },
  {
    key: 'EU',
    value: 'Europe',
    languages: [
      'en-GB,en-US',
      'sv-SE',
      'de-DE',
      'fr-FR',
      'es-ES',
      'el-GR',
      'it-IT',
      'ro-RO',
      'pt-PT',
      'sk-SK',
      'nl-NL',
      'da-DK',
      'no-NO',
      'fi-FI',
      'pl-PL',
      'hu-HU',
      'hr-HR',
      'cs-CZ',
      'et-EE',
      'lv-LV',
      'sl-SI',
      'lt-LT',
      'bg-BG',
      'tr-TR',
      'sr-Latn-RS',
    ],
    countries: [
      'Andorra',
      'Belgium',
      'Belarus',
      'Bosnia and Herzegovina',
      'Bulgaria',
      'Cyprus',
      'Czech Republic',
      'Montenegro',
      'Denmark',
      'Estonia',
      'Germany',
      'Ireland',
      'Spain',
      'France',
      'Greece',
      'Croatia',
      'Iceland',
      'Italy',
      'Latvia',
      'Lithuania',
      'Liechtenstein',
      'Luxembourg',
      'Hungary',
      'Malta',
      'Monaco',
      'Netherlands',
      'Norway',
      'Austria',
      'Poland',
      'Portugal',
      'Moldova',
      'Romania',
      'North Macedonia',
      'Albania',
      'Slovenia',
      'Slovakia',
      'Serbia',
      'Switzerland',
      'Finland',
      'Sweden',
      'Turkey',
      'Ukraine',
      'United Kingdom',
    ],
  },
  {
    key: 'NA',
    value: 'North America',
    languages: ['en-GB,en-US', 'fr-FR,fr-CA', 'es-ES,es-XL,es-SA'],
    countries: ['Canada', 'Canada(fr)', 'Mexico', 'United States'],
  },
  {
    key: 'SA',
    value: 'South America',
    languages: ['en-GB,en-US', 'pt-BR', 'es-ES,es-XL,es-SA'],
    countries: [
      'Brazil',
      'Brasil (Brazil - Condor)',
      'Colombia',
      'Argentina',
      'Peru',
      'Venezuela',
      'Chile',
      'Ecuador',
      'Guatemala',
      'Bolivia',
      'Honduras',
      'Paraguay',
      'El Salvador',
      'Nicaragua',
      'Costa Rica',
      'Panama',
      'Uruguay',
      'Guyana',
      'Suriname',
      'Belize',
      'Saint Vincent and the Grenadines',
      'Saint Lucia',
      'Antigua and Barbuda',
      'Saint Kitts and Nevis',
      'Haiti',
      'Bahamas',
      'Barbados',
      'Cuba',
      'Dominica',
      'Dominican Republic',
      'Grenada',
      'Jamaica',
      'Trinidad and Tobago',
    ],
  },
  { key: 'IN', value: 'India' },
  { key: 'AU', value: 'Australia' },
];

export const orderBy: InputObject[] = [
  { key: 'Date', value: 'Release date' },
  { key: 'Relevance', value: 'Relevance' },
  { key: 'Manualtitle', value: 'Product name' },
];

export const defaultOrderBy = 'Relevance';

export const languages: InputObject[] = [
  { key: 'en-GB', value: 'English (UK)' },
  { key: 'en-US', value: 'English (US)' },
  { key: 'en-AU', value: 'English (AU)' },
  { key: 'en-AU,en-US,en-GB', value: 'English' },
  { key: 'en-GB,en-US', value: 'English' },
  { key: 'ar-EG', value: 'Arabic' },
  { key: 'pt-BR', value: 'Brazilian Portuguese' },
  { key: 'bg-BG', value: 'Bulgarian' },
  { key: 'zh-CN', value: 'Chinese' },
  { key: 'hr-HR', value: 'Croatian' },
  { key: 'cs-CZ', value: 'Czech' },
  { key: 'da-DK', value: 'Danish' },
  { key: 'nl-NL', value: 'Dutch' },
  { key: 'et-EE', value: 'Estonian' },
  { key: 'fi-FI', value: 'Finnish' },
  { key: 'fr-FR,fr-CA', value: 'French' },
  { key: 'fr-FR', value: 'French (FR)' },
  { key: 'fr-CA', value: 'French (CA)' },
  { key: 'de-DE', value: 'German' },
  { key: 'el-GR', value: 'Greek' },
  { key: 'hu-HU', value: 'Hungarian' },
  { key: 'it-IT', value: 'Italian' },
  { key: 'ko-KR', value: 'Korean' },
  { key: 'lv-LV', value: 'Latvian' },
  { key: 'lt-LT', value: 'Lithuanian' },
  { key: 'no-NO', value: 'Norwegian' },
  { key: 'pl-PL', value: 'Polish' },
  { key: 'pt-PT', value: 'Portuguese' },
  { key: 'ro-RO', value: 'Romanian' },
  { key: 'sk-SK', value: 'Slovak' },
  { key: 'sl-SI', value: 'Slovenian' },
  { key: 'es-ES', value: 'Spanish (ES)' },
  { key: 'es-XL', value: 'Spanish (XL)' },
  { key: 'es-SA', value: 'Spanish (SA)' },
  { key: 'es-ES,es-XL,es-SA', value: 'Spanish' },
  { key: 'sv-SE', value: 'Swedish' },
  { key: 'tr-TR', value: 'Turkish' },
  { key: 'sr-Latn-RS', value: 'Serbian' },
  { key: 'ma-NY', value: 'Multiple languages' },
];
export const defaultLanguage = '';

export const types: Array<InputObject & { access?: string }> = [
  { key: 'IM', value: 'Instruction manual' },
  { key: 'SPL', value: 'Spare parts list (Replacement parts)' },
  { key: 'ACC', value: 'Accessories' },
  { key: 'AI', value: 'Assembly instruction' },
  { key: 'DOC', value: 'Declaration of Conformity' },
  { key: 'FT', value: 'Fault tracing guide' },
  { key: 'HB', value: 'Handbook' },
  { key: 'HTML', value: 'HTML Manual' },
  { key: 'ILM', value: 'Installation manual' },
  { key: 'INT', value: 'Integrator manual' },
  { key: 'PG', value: 'Product guide' },
  { key: 'PM', value: 'Programming manual' },
  { key: 'QG', value: 'Quick guide' },
  { key: 'QSG', value: 'Quick start guide' },
  { key: 'SI', value: 'Safety instruction' },
  { key: 'SM', value: 'Service manual' },
  { key: 'SU', value: 'Software upgrade instruction' },
  { key: 'TI', value: 'Troubleshooting instruction' },
  { key: 'WP', value: 'Wear parts' },
  { key: 'WD', value: 'Wiring diagram' },
];
export const defaultType = '';

export type OptionGroup = {
  title: string;
  items: InputObject[];
};

export type SelectItem = InputObject | OptionGroup;

export function isOptionGroup(item: SelectItem): item is OptionGroup {
  return (item as OptionGroup).title !== undefined;
}

export const categories: SelectItem[] = [
  { key: 'gas', value: 'Gas Equipment' },
  { key: 'arx', value: 'Carbon Arc Gouging / Exothermic Cutting' },
  { key: 'wel', value: 'Welding Automation' },
  { key: 'arc', value: 'Welding Equipment' },
  { key: 'pls', value: 'Manual Plasma Cutting' },
  { key: 'cut', value: 'Cutting Automation' },
  { key: 'ppe', value: 'PPE / Safety' },
  { key: 'aac', value: 'Accessories and Consumables' },
  { key: 'rob', value: 'Robotics' },
];

export const itemsPerPage = 10;

// Helper to get item from array above by key
export const valueForKey = (key: string, items: InputObject[]) => {
  const item = items.find((item) => item.key === key);

  if (!item) {
    console.error(`Could not find value for key '${key}' in object`, items);
    return null;
  }

  return item.value;
};

// https://davidwalsh.name/query-string-javascript
export const getUrlParameter = (name: string): string => {
  const escapedName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + escapedName + '=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const urlParameterIsTrue = (name: string): boolean => {
  const parameter = getUrlParameter(name);
  return parameter === '1';
};
