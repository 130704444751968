import { useParams, useLocation } from 'react-router-dom';
import { useCallback } from 'react';

import { BASE_PATH } from '../components/helpers/urlUtils';

export const useInternalLink = () => {
  const { manifestId } = useParams<{ manifestId: string }>();
  const location = useLocation();

  const internalLink = useCallback(
    (path: string) => {
      if (location.pathname.includes(`${BASE_PATH}/`)) {
        const cleanPath = path.startsWith('/') ? path : `/${path}`;
        const normalizedPath = cleanPath.replace(`${BASE_PATH}/`, '/');
        if (normalizedPath.includes(manifestId)) {
          return `${BASE_PATH}${normalizedPath}`;
        }
        return `${BASE_PATH}/${manifestId}${normalizedPath}`;
      }
      return path;
    },
    [manifestId, location]
  );

  return { internalLink };
};